import { SxProps, Theme } from '@mui/material';

export const headerStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  padding: {
    xs: '10px 20px',  // Small padding for extra small screens (mobile)
    sm: '15px 30px',  // Slightly larger padding for small screens (tablet)
    md: '20px 40px',  // Default for medium screens (desktop)
  },
  background: 'linear-gradient(to right, #ffffff, #f8f8f8)',
  borderBottom: '2px solid #e0e0e0',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  flexDirection: {
    xs: 'column',    // Stack items vertically on mobile
    md: 'row',       // Horizontal layout on larger screens
  },
};

export const titleStyles: SxProps<Theme> = {
  fontWeight: 'bold',
  color: '#333',
  letterSpacing: '1px',
  fontSize: '1.2rem',  // Fixed small font size for all screen sizes
  textAlign: {
    xs: 'center',  // Center the title on mobile
    md: 'left',    // Align left on larger screens
  },
  marginBottom: {
    xs: '10px',    // Add some space between title and buttons on mobile
    md: '0px',     // No space needed on larger screens
  },
};

export const buttonStyles = (selectedType: string | null, type: string): SxProps<Theme> => ({
  marginRight: {
    xs: '5px',   // Smaller margin on mobile
    sm: '10px',  // Default margin for tablet and desktop
  },
  marginBottom: {
    xs: '10px',  // Add space between buttons on mobile (since they're stacked vertically)
    md: '0px',   // No space on larger screens (buttons are side by side)
  },
  color: selectedType === type ? '#fff' : '#1976d2',
  backgroundColor: selectedType === type ? '#1976d2' : 'transparent',
  borderRadius: '20px',
  borderColor: '#1976d2',
  transition: 'background-color 0.3s, color 0.3s',
  width: {
    xs: '100%',  // Full width buttons on mobile for easy interaction
    sm: 'auto',  // Default size on larger screens
  },
  '&:hover': {
    backgroundColor: '#1976d2',
    color: '#fff',
  },
});
