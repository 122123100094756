// Header.tsx

import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { headerStyles, titleStyles, buttonStyles } from './HeaderStyles';
import Banner from './Banner';

interface HeaderProps {
  setSelectedType: (type: string) => void;
}

const Header: React.FC<HeaderProps> = ({ setSelectedType }) => {
  const [selectedType, setSelectedTypeState] = useState<string | null>(null);

  const handleButtonClick = (type: string) => {
    setSelectedType(type);
    setSelectedTypeState(type);
  };

  return (
    <>
    <Box sx={headerStyles}>
      <Typography variant="h6" component="div" sx={titleStyles}>
        FINDMY.FASHION
      </Typography>
      <Box>
        {['women', 'men', 'kids'].map((type) => (
          <Button
            key={type}
            variant="outlined"
            sx={buttonStyles(selectedType, type)}
            onClick={() => handleButtonClick(type)}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Button>
        ))}
      </Box>
    </Box>
    <Banner />
    </>
  );
};

export default Header;
