// About.tsx

import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText, Divider, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

const AboutUs: React.FC = () => {
  const navigate = useNavigate(); // Create navigate function

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box sx={{ backgroundColor: '#f7f7f7', py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          About FindMyFashion
        </Typography>

        <Typography variant="h6" align="center" sx={{ mb: 4, color: '#666' }}>
          Your personalized fashion guide to finding the perfect fit.
        </Typography>

        {/* What FindMyFashion Is Designed For */}
        <Box sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            What FindMyFashion Is Designed For:
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            FindMyFashion is your free go-to solution for finding fashion and clothing essentials that fit you.
            Our app uses the garment measurements from an item that fits you well to rank options from
            multiple retailers in order of best fit. Whether you’re shopping for a specific style or everyday
            essentials, we help you find clothes that match your unique shape—no guesswork, no confusing
            size charts, and fewer returns.
          </Typography>
        </Box>

        <Divider sx={{ my: 5 }} />

        {/* Who Benefits from FindMyFashion */}
        <Box sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            Who Benefits from FindMyFashion?
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            FindMyFashion is designed for anyone looking to make online shopping easier, especially:
          </Typography>
          <Grid container spacing={2}>
            {[
              'Busy people who need a quick, time-saving solution.',
              'Frustrated shoppers tired of ordering the wrong size and dealing with returns.',
              'People with unique body types—whether you’re tall, petite, plus-size or somewhere in between.',
              'Sustainability-conscious shoppers seeking to reduce waste by avoiding unnecessary returns.',
              'Fashion-forward individuals seeking on-trend looks in their size.'
            ].map((text, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListItem disableGutters>
                  <ListItemText primary={text} primaryTypographyProps={{ variant: 'body1', color: '#555' }} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ my: 5 }} />

        {/* Benefits of Using FindMyFashion */}
        <Box sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            Benefits of Using FindMyFashion:
          </Typography>
          <Grid container spacing={2}>
            {[
              'Save time and shop confidently, knowing you’re browsing clothes that will fit.',
              'Reduce the hassle of returns, saving both effort and shipping waste.',
              'Find options that suit your body type, regardless of inconsistent size charts.',
              'Access a wide range of brands, with clothes ranked according to how well they match your measurements.'
            ].map((benefit, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListItem disableGutters>
                  <ListItemText primary={benefit} primaryTypographyProps={{ variant: 'body1', color: '#555' }} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ my: 5 }} />

        {/* Why Sizing Matters */}
        <Box sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            Why Sizing Matters: The Key to Finding Clothes That Truly Fit
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            Inconsistent sizing can make online shopping a nightmare. A recent report from Cosmopolitan
            highlights how dramatically sizes vary across high street shops, confusing shoppers. According to
            Time, size charts differ significantly between brands, adding to the frustration. This is where
            FindMyFashion helps—our app uses your garment measurements to find clothes that fit, solving
            the pain point of inconsistent sizes across retailers.
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            Additionally, the unsustainable rate of returns, often due to ill-fitting clothes, contributes
            significantly to environmental waste. Eco-Age reports that the carbon footprint of these returns
            is staggering, making it clear that finding the right fit matters—not just for you, but for the
            planet too.
          </Typography>
        </Box>

        {/* Statistics Section */}
        <Box sx={{ mt: 5, backgroundColor: '#f1f1f1', p: 3, borderRadius: 2 }}>
          <Typography variant="body2" sx={{ color: '#333' }}>
            <strong>Between 30-40% of clothes bought online are now returned to the seller.</strong> - Eco-Age
          </Typography>
          <Typography variant="body2" sx={{ color: '#333' }}>
            <strong>75% of people have issues finding clothing that fits.</strong> - MyGreen Closet
          </Typography>
          <Typography variant="body2" sx={{ color: '#333' }}>
            <strong>Clothes sizing also differs internationally,</strong> which is an added complication given how many garments are shipped around the world. - The Guardian
          </Typography>
          <Typography variant="body2" sx={{ color: '#333' }}>
            <strong>According to Mintel, 47% of men find sizing technology helpful when shopping online.</strong>
          </Typography>
        </Box>

        {/* Go Back Button */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            sx={{ backgroundColor: '#333', '&:hover': { backgroundColor: '#555' } }}
          >
            Go Back
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
