import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Measurements from './pages/MeasurementsPage';
import AboutUs from './components/About';
import { trackPageView } from './analytics';

const App: React.FC = () => {
  return (
    <Router>
      <PageTracker />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/measurements" element={<Measurements />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
    </Router>
  );
};

const PageTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;

