// src/components/RetailerDropdown.tsx

import React from 'react';
import { Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';

interface RetailerDropdownProps {
  selectedRetailer: string;
  handleRetailerChange: (event: SelectChangeEvent) => void;
}

const RetailerDropdown: React.FC<RetailerDropdownProps> = ({
  selectedRetailer,
  handleRetailerChange,
}) => {
  return (
    <Box sx={{ marginBottom: '16px', width: 'fit-content' }}>
      <Select
        value={selectedRetailer}
        onChange={handleRetailerChange} // Ensure proper typing
        sx={{
          width: '200px', // Set a specific width
          backgroundColor: "#fff",
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ddd",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#aaa",
          },
        }}
      >
        <MenuItem value="Mytheresa">Mytheresa</MenuItem>
        <MenuItem value="Zara" disabled>
          Zara (coming soon)
        </MenuItem>
        <MenuItem value="Pull&Bear" disabled>
          Pull&Bear (coming soon)
        </MenuItem>
        <MenuItem value="More" disabled>
          More
        </MenuItem>
      </Select>
    </Box>
  );
};

export default RetailerDropdown;
