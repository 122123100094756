// AdvancedSearchStyles.ts

import { SxProps, Theme } from "@mui/material/styles";

export const containerStyle: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
  padding: { xs: "12px", sm: "16px", md: "0" },
  backgroundColor: { xs: theme.palette.background.paper, md: "transparent" },
  borderRadius: { xs: "8px", md: "0" },
  boxShadow: { xs: theme.shadows[2], md: "none" },
  transform: { xs: "scale(1)", md: "scale(0.75)" },
  transformOrigin: { xs: "center", md: "top left" },
});

export const titleStyle: SxProps<Theme> = (theme) => ({
  color: theme.palette.text.primary,
  fontSize: { xs: "1rem", md: "1.1rem" },
  textAlign: { xs: "center", md: "left" },
});

export const toggleContainerStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: { xs: "12px", md: "16px" },
  flexDirection: { xs: "column", sm: "row" },
  justifyContent: { xs: "center", sm: "flex-start" },
};

export const toggleLabelStyle: SxProps<Theme> = {
  marginBottom: { xs: "8px", md: "6px" },
};

export const tooltipContainerStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: { xs: "6px", md: "8px" },
  justifyContent: { xs: "center", md: "flex-start" },
};

export const tooltipTitleStyle: SxProps<Theme> = {
  color: "#555",
  fontWeight: "bold",
  textAlign: { xs: "center", md: "left" },
};

export const measurementGridStyle: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
  gap: { xs: "8px", md: "12px" },
};

export const saveButtonStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  "&:hover": { backgroundColor: theme.palette.primary.dark },
  marginTop: "12px",
  padding: { xs: "10px 20px", sm: "8px 16px" },
  fontSize: { xs: "0.9rem", md: "14px" },
  alignSelf: { xs: "center", md: "flex-start" },
  width: { xs: "100%", sm: "auto" },
});
