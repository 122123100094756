// mappings.ts


import { Measurements } from './types';

export const fieldsMap: { [key: string]: { [key: string]: (keyof Measurements)[] } } = {
  Knitwear: {
    Turtlenecks: ['length', 'chest', 'waist', 'sleeve'],
    Sweaters: ['length', 'chest', 'waist', 'sleeve'],
    Cardigans: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'],
    Dresses: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'],  // Added Dresses subcategory
    'Polo_sweaters': ['length', 'chest', 'sleeve', 'shoulder'], // Updated to A through D
    'Zip-up': ['length', 'chest', 'sleeve', 'shoulder'], // Updated to A through D 
    Cashmere: ['length', 'chest', 'sleeve', 'shoulder'], // Updated to A through D
    'Sweater_Vests': ['length', 'chest', 'waist'], // A through C
  },

  Tops: {
    Default: ['length', 'chest'],
    'Sleeveless_tops': ['length', 'chest', 'waist'], // A through C
    'Long-sleeved_tops': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
  },
  Pants: {
    Default: ['waist', 'hip', 'thigh', 'inseam'],
    'Flared_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Straight_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Skinny_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Leggings: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Casual_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Chinos: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Wide-leg_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Drawstring: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Slim-leg': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Formal_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Cargo: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Leather_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Wide-leg': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Cargo_Pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Culottes: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
  },
  Jackets: {
    Default: ['length', 'chest', 'sleeve'],
    Blazers: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    Bombers: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Leather_jackets: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
    'Bomber_& Track Jackets': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
    'Capes_& ponchos': ['length', 'chest'], // A through B
    Shearling_Jackets: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
    'Faux-fur_jackets': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
    Casual: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    'Leather_& Suede': ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Gilets: ['length', 'chest', 'waist'], // A through C
    'Vests_& gilets': ['length', 'chest', 'waist'], // A through C
    Puffer: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Lightweight: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Wool_Jackets: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    'Puffer_& Down Jackets': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    Denim_Jackets: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F (unchanged)
    'Tweed_Jackets': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    Denim: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    'Overshirts_& Utility Jackets': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
    Overshirts: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Evening_jackets: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // Updated to A through F
  },


  Coats: {
    Default: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'],
    'Trench_coats & raincoats': ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Update to A through F
    Puffer: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Update to A through F
    Parkas: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Update to A through F
  },

  'Lingerie_& hosiery': {
    Default: ['waist', 'hip', 'thigh', 'inseam', 'rise'],
    Lingerie: ['length', 'chest', 'hip'],
    Hosiery: ['waist', 'hip', 'thigh', 'inseam', 'rise'],
  },
  Dresses: {
    Default: ['length', 'chest', 'waist', 'hip'],
    'Tunics_and kaftans': ['length', 'chest'], // A through B
    'Midi_dresses': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    Minidresses: ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    'Maxi_dresses': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
  },
  Skirts: { Default: ['length', 'waist', 'hip'] },
  Jeans: { Default: ['waist', 'hip', 'thigh', 'inseam', 'rise'] },
  Activewear: {
    Default: ['length', 'chest', 'waist', 'sleeve'],
    Bottoms: ['waist', 'hip', 'thigh', 'inseam', 'rise'],
    'Sports_bras': ['length', 'chest', 'waist'],
    Tops: ['length', 'chest', 'shoulder'],
    'Sports_leggings': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Sports_outerwear': ['length', 'chest', 'waist', 'hip', 'shoulder', 'sleeve'], // A through F
    'Sports_Skirts': ['length', 'waist', 'hip'], // Updated to A through C
    'Sports_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    Jackets: ['length', 'chest', 'sleeve', 'waist', 'hip'], // Updated to A through E
    'Sports_Dresses': ['length', 'chest', 'waist', 'hip'], // Updated to A through D
    'Sports_shorts': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
    'Sports_Jumpsuits': ['chest', 'waist', 'hip','shoulder', 'inseam'], // Updated to A through F
  },

  Sweats: {
    Default: ['waist', 'hip', 'thigh', 'inseam'],
    Sweatpants: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // Updated to A through E
    Sweatshirts: ['length', 'chest', 'sleeve', 'shoulder'], // Updated to A through D
    Hoodies: ['length', 'chest', 'sleeve', 'shoulder'], // Updated to A through D
  },

  Beachwear: {
    Default: ['length', 'chest'],
    Bikinis: ['length', 'chest', 'waist'], // Updated to A through C
  },

  Overcoats: {
    Default: ['length', 'chest', 'waist', 'hip', 'sleeve'],
    Single_breasted: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Double_breasted: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
  },

  'Casual_shirts': {
    Default: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder', 'collarWidth'],
    'Short-sleeved_shirts': ['length', 'chest', 'shoulder'], // Updated to A through C
  },
  
  Sweaters: { Default: ['length', 'chest', 'sleeve', 'shoulder'] },
  'T-shirts': {
    Default: ['length', 'chest', 'shoulder'],
    'Long-sleeved_T-shirts': ['length', 'chest', 'sleeve', 'shoulder'], // A through D
  },
  'Polo_shirts': {
    Default: ['length', 'chest', 'shoulder'],
    'Long-sleeved_polo shirts': ['length', 'chest', 'sleeve', 'shoulder'], // A through D
  },

  Suits: {
    Default: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder', 'thigh', 'inseam', 'rise'],
    Day_suits: [
      'length',        // A Length
      'chest',         // B Chest
      'sleeve',        // C Sleeve
      'waist',         // D Waist Of Blazer
      'hip',           // E Hip Of Blazer
      'shoulder',      // F Shoulder
      'waist_trouser', // G Waist (Trousers/Skirt)
      'hip_trouser',   // H Hip (Trousers/Skirt)
      'thigh',         // I Thigh
      'inseam',        // J Inseam
      'rise'           // K Rise
    ],
    Evening_suits: [
      'length',        // A Length
      'chest',         // B Chest
      'sleeve',        // C Sleeve
      'waist',         // D Waist Of Blazer
      'hip',           // E Hip Of Blazer
      'shoulder',      // F Shoulder
      'waist_trouser', // G Waist (Trousers/Skirt)
      'hip_trouser',   // H Hip (Trousers/Skirt)
      'thigh',         // I Thigh
      'inseam',        // J Inseam
      'rise'           // K Rise
    ],
  },
  Shirts: { Default: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder', 'collarWidth'] },
  Skiwear: {
    Default: ['length', 'chest', 'sleeve'],
    'Ski_Suits': ['chest', 'hip', 'inseam'], // A through C
    'Ski_Pants': ['waist', 'hip', 'thigh', 'inseam'], // A through D
    'Ski_Sweaters': ['length', 'chest', 'waist', 'sleeve'], // A through D
  },
  'Underwear_& Sleepwear': {
    Default: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder', 'thigh', 'inseam', 'rise'],
    Robes: ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder'], // Updated to A through F
    Pyjama: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // Updated to A through E
    Briefs: ['waist', 'hip', 'thigh', 'inseam', 'rise'], // Updated to A through E
 'Pyjama_set': ['length', 'chest', 'sleeve', 'waist', 'hip', 'shoulder', 'waist_trouser', 'hip_trouser', 'thigh', 'inseam', 'rise'], // A through K

  },
  



  Denim: {
    Default: ['waist', 'hip', 'thigh', 'inseam'],
    'Denim_pants': ['waist', 'hip', 'thigh', 'inseam', 'rise'], // A through E
  },
};

export const labelMap: { [key: string]: { [key: string]: string[] } } = {
  Knitwear: {
    Turtlenecks: ['A Length', 'B Chest', 'C Waist', 'D Sleeve'],
    Sweaters: ['A Length', 'B Chest', 'C Waist', 'D Sleeve'],
    Cardigans: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'],
    Dresses: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'],  // Added Dresses subcategory
    'Polo_sweaters': ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // Updated to A through D
    'Zip-up': ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // Updated to A through D
    Cashmere: ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // Updated to A through D
    'Sweater_Vests': ['A Length', 'B Chest', 'C Waist'], // A through C
  },

  Tops: {
    Default: ['A Length', 'B Chest'],
    'Sleeveless_tops': ['A Length', 'B Chest', 'C Waist'], // A through C
    'Long-sleeved_tops': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
  },
  Pants: {
    Default: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam'],
    'Flared_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Straight_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Skinny_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Leggings: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Casual_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Chinos: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Wide-leg_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Drawstring: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Slim-leg': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Formal_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Cargo: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Leather_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Wide-leg': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Cargo_Pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Culottes: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E



  },

  Jackets: {
    Default: ['A Length', 'B Chest', 'C Sleeve'],
    Blazers: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    Bombers: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Leather_jackets: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
    'Bomber_& Track Jackets': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
    'Capes_& ponchos': ['A Length', 'B Chest'], // A through B
    Shearling_Jackets: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
    'Faux-fur_jackets': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
    Casual: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    'Leather_& Suede': ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Gilets: ['A Length', 'B Chest', 'C Waist'], // A through C
    'Vests_& gilets': ['A Length', 'B Chest', 'C Waist'], // A through C
    Puffer: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Lightweight: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Wool_Jackets: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    'Puffer_& Down Jackets': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    Denim_Jackets: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F (unchanged)
    'Tweed_Jackets': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    Denim: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    'Overshirts_& Utility Jackets': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
    Overshirts: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Evening_jackets: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // Updated to A through F
  },
  Coats: {
    Default: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'],
    'Trench_coats & raincoats': ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Update to A through F
    Puffer: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Update to A through F
    Parkas: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Update to A through F
  },


  'Lingerie_& hosiery': {
    Default: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'],
    Lingerie: ['A Length', 'B Chest', 'C Hip'],
    Hosiery: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'],

  },
  Dresses: {
    Default: ['A Length', 'B Chest', 'C Waist', 'D Hip'],
    'Tunics_and kaftans': ['A Length', 'B Chest'], // A through B
    'Midi_dresses': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    Minidresses: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    'Maxi_dresses': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
  },
  Skirts: { Default: ['A Length', 'B Waist', 'C Hip'] },
  Jeans: { Default: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'] },
  Activewear: {
    Default: ['A Length', 'B Chest', 'C Waist', 'D Sleeve'],
    Bottoms: ['A Waistband', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'],
    'Sports_bras': ['A Length', 'B Chest', 'C Waist'],
    Tops: ['A Length', 'B Chest', 'C Shoulder'],
    'Sports_leggings': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Sports_outerwear': ['A Length', 'B Chest', 'C Waist', 'D Hip', 'E Shoulder', 'F Sleeve'], // A through F
    'Sports_Skirts': ['A Length', 'B Waist', 'C Hip'], // Updated to A through C
    'Sports_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    Jackets: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip'], // Updated to A through E
    'Sports_Dresses': ['A Length', 'B Chest', 'C Waist', 'D Hip'], // Updated to A through D
    'Sports_shorts': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
    'Sports_Jumpsuits': ['A Chest', 'B Waist', 'C Hip', 'D Shoulder', 'F Inseam'], // Updated to A through F
  },

  Sweats: {
    Default: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam'],
    Sweatpants: ['A Waistband', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // Updated to A through E
    Sweatshirts: ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // Updated to A through D
    Hoodies: ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // Updated to A through D
  },

  Beachwear: {
    Default: ['A Length', 'B Chest'],
    Bikinis: ['A Length', 'B Chest', 'C Waist'], // Updated to A through C
  },


  Overcoats: {
    Default: ['A Length', 'B Chest', 'C Waist', 'D Hip', 'F Sleeve'],
    Single_breasted: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Double_breasted: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
  },

  'Casual_shirts': {
    Default: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder', 'G Collar width'],
    'Short-sleeved_shirts': ['A Length', 'B Chest', 'C Shoulder'], // Updated to A through C
  },
  
  Sweaters: { Default: ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'] },
  'T-shirts': {
    Default: ['A Length', 'B Chest', 'C Shoulder'],
    'Long-sleeved_T-shirts': ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // A through D
  },
  'Polo_shirts': {
    Default: ['A Length', 'B Chest', 'C Shoulder'],
    'Long-sleeved_polo shirts': ['A Length', 'B Chest', 'C Sleeve', 'D Shoulder'], // A through D
  },

  Suits: {
    Default: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder', 'G Waist', 'H Hip', 'I Thigh', 'J Inseam', 'K Rise'],
    Day_suits: [
      'A Length',           // A Length
      'B Chest',            // B Chest
      'C Sleeve',           // C Sleeve
      'D Waist Of Blazer',  // D Waist Of Blazer
      'E Hip Of Blazer',    // E Hip Of Blazer
      'F Shoulder',         // F Shoulder
      'G Waist',            // G Waist (Trousers/Skirt)
      'H Hip',              // H Hip (Trousers/Skirt)
      'I Thigh',            // I Thigh
      'J Inseam',           // J Inseam
      'K Rise'              // K Rise
    ],
    Evening_suits: [
      'A Length',           // A Length
      'B Chest',            // B Chest
      'C Sleeve',           // C Sleeve
      'D Waist Of Blazer',  // D Waist Of Blazer
      'E Hip Of Blazer',    // E Hip Of Blazer
      'F Shoulder',         // F Shoulder
      'G Waist',            // G Waist (Trousers/Skirt)
      'H Hip',              // H Hip (Trousers/Skirt)
      'I Thigh',            // I Thigh
      'J Inseam',           // J Inseam
      'K Rise'              // K Rise
    ],
  },
  Shirts: { Default: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder', 'G Collar width'] },
  Skiwear: {
    Default: ['A Length', 'B Chest', 'C Sleeve'],
    'Ski_Suits': ['A Chest', 'B Hip', 'C Inseam'], // A through C
    'Ski_Pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam'], // A through D
    'Ski_Sweaters': ['A Length', 'B Chest', 'C Waist', 'D Sleeve'], // A through D
  },
  'Underwear_& Sleepwear': {
    Default: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder', 'G Thigh', 'H Inseam', 'I Rise'],
    Robes: ['A Length', 'B Chest', 'C Sleeve', 'D Waist', 'E Hip', 'F Shoulder'], // Updated to A through F
    Pyjama: ['A Waistband', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // Updated to A through E
    Briefs: ['A Waistband', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // Updated to A through E
   'Pyjama_set': [
    'A Length',
    'B Chest',
    'C Sleeve',
    'D Waist of Blazer',
    'E Hip of Blazer',
    'F Shoulder',
    'G Waist of Trousers/Skirt',
    'H Hip of Trousers/Skirt',
    'I Thigh',
    'J Inseam',
    'K Rise'
], // A through K


  },
  


  Denim: {
    Default: ['A Waist', 'B Hip', 'C Thigh', 'D Inseam'],
    'Denim_pants': ['A Waist', 'B Hip', 'C Thigh', 'D Inseam', 'E Rise'], // A through E
  },
};
