//useMailChimpSignup.ts


import { useState } from 'react';

const useMailchimpSignup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const subscribe = async (email: string) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Use your new backend API endpoint
      const response = await fetch('https://mail-ufy0.onrender.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(true);  // Subscription was successful
      } else {
        setError(data.error || 'Failed to subscribe. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);  // Stop the loading state
    }
  };

  return { subscribe, isLoading, error, success };
};

export default useMailchimpSignup;

