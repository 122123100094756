// FilterComponentStyles.ts

import { SxProps, Theme } from '@mui/material/styles';

export const containerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: (theme) => theme.shadows[3],
  width: '100%',
};

export const retailerDropdownStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" }, // Stack on smaller screens
  alignItems: { xs: "stretch", sm: "center" }, // Stretch dropdowns on smaller screens
  justifyContent: { xs: "flex-start", sm: "space-between" }, 
  flexWrap: "wrap", // Allow wrapping to prevent overlap
  width: "100%",
  gap: { xs: "16px", sm: "16px" }, // Unified gap for smaller and larger screens
  padding: "8px 0", // Maintain padding for consistent spacing
  "& > *": {
    flexGrow: 1, // Ensure equal width for dropdowns on smaller screens
    minWidth: "150px", // Prevent collapsing on extremely small screens
  },
};

export const inputGroupStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  flex: 1,
  flexWrap: "wrap", // Allow inputs to wrap when space is limited
};

export const selectStyle: SxProps<Theme> = {
  minWidth: '200px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ddd',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#aaa',
  },
};

export const selectedCategoryImageStyle: SxProps<Theme> = {
  width: '180px',
  height: '180px',
  borderRadius: '8px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  alignSelf: "center", // Center-align the image on smaller screens
  "@media (max-width: 600px)": {
    width: "120px",
    height: "120px", // Resize the image for smaller screens
  },
};

export const priceRangeWrapperStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
};

export const priceRangeContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'center',
  flexDirection: { xs: 'column', sm: 'row' },
  width: "100%",
};

export const priceRangeTextStyle: SxProps<Theme> = {
  color: '#555',
  fontWeight: 'bold',
  textAlign: 'center',
};

export const toTextStyle: SxProps<Theme> = {
  color: '#555',
};

export const buttonStyle: SxProps<Theme> = {
  width: { xs: "100%", sm: "150px" }, // Full-width on smaller screens
  backgroundColor: '#ff4081',
  '&:hover': {
    backgroundColor: '#f50057',
  },
  fontSize: '1rem',
  padding: { xs: '12px', sm: '10px 20px' }, // Larger touch target for smaller screens
};

export const priceInputStyle: React.CSSProperties = {
  width: '100%', // Full-width on smaller screens
  maxWidth: '120px', // Limit width on larger screens
  padding: '8px',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxSizing: 'border-box',
};

