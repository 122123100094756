//src/analytics.ts
import ReactGA from 'react-ga4';

// Initialize GA with your Measurement ID
export const initGA = () => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-YF9VHR8X32';
    
    if (!measurementId) {
        console.error("GA Measurement ID is missing! Check your .env file.");
        return;
    }
    
    console.log('GA Measurement ID:', measurementId);
    ReactGA.initialize(measurementId);
};

// Track page views
export const trackPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};

// Track custom events (optional)
export const trackEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
        category,
        action,
        label,
    });
};
