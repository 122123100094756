// AdvancedSearch.tsx



import React from "react";
import {
  Box,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { fieldsMap, labelMap } from "../utils/mappings";
import { Measurements } from "../utils/types";

import {
  containerStyle,
  titleStyle,
  toggleContainerStyle,
  toggleLabelStyle,
  tooltipContainerStyle,
  tooltipTitleStyle,
  measurementGridStyle,
  saveButtonStyle,
} from "./AdvancedSearchStyles";

interface AdvancedSearchProps {
  measurements: Measurements;
  isMetric: boolean;
  saved: boolean;
  selectedCategory: string;
  selectedSubcategory: string;
  handleUnitToggle: () => void;
  handleMeasurementChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  saveMeasurements: () => void;
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  measurements,
  isMetric,
  saved,
  selectedCategory,
  selectedSubcategory,
  handleUnitToggle,
  handleMeasurementChange,
  saveMeasurements,
}) => {
  const convertInchesToCm = (inches: string): string => {
    const valueInCm = parseFloat(inches) * 2.54;
    return valueInCm.toFixed(2); // Round to 2 decimal places
  };

  const handleSaveMeasurements = () => {
    let measurementsToSave = { ...measurements };

    if (!isMetric) {
      measurementsToSave = {
        ...measurementsToSave,
        length: convertInchesToCm(measurements.length),
        chest: convertInchesToCm(measurements.chest),
        waist: convertInchesToCm(measurements.waist),
        hip: convertInchesToCm(measurements.hip),
        shoulder: convertInchesToCm(measurements.shoulder),
        sleeve: convertInchesToCm(measurements.sleeve),
        thigh: convertInchesToCm(measurements.thigh),
        inseam: convertInchesToCm(measurements.inseam),
        rise: convertInchesToCm(measurements.rise),
        collarWidth: convertInchesToCm(measurements.collarWidth),
        waist_trouser: convertInchesToCm(measurements.waist_trouser),
        hip_trouser: convertInchesToCm(measurements.hip_trouser),
      };
    }

    console.log("Saving measurements including max fields:", measurementsToSave);

    localStorage.setItem("customMeasurements", JSON.stringify(measurementsToSave));
    saveMeasurements();
  };

  const getFieldsForCategory = (category: string, subcategory: string) => {
    return (
      fieldsMap[category]?.[subcategory] || fieldsMap[category]?.Default || []
    );
  };

  const getLabelForField = (
    category: string,
    subcategory: string,
    field: keyof typeof measurements
  ) => {
    const fields: (keyof typeof measurements)[] = getFieldsForCategory(
      category,
      subcategory
    );
    const index = fields.indexOf(field);

    const labelFromMap =
      labelMap[category]?.[subcategory]?.[index] ||
      labelMap[category]?.Default?.[index];

    const defaultLabel = field.charAt(0).toUpperCase() + field.slice(1);

    const finalLabel = labelFromMap
      ? labelFromMap
      : `${String.fromCharCode(65 + index)} ${defaultLabel}`;

    return finalLabel;
  };

  const fields: (keyof typeof measurements)[] = getFieldsForCategory(
    selectedCategory,
    selectedSubcategory
  );

  return (
    <Box sx={containerStyle}>
      <Typography variant="h6" sx={titleStyle}>
        Advanced Search
      </Typography>

      <Box sx={toggleContainerStyle}>
        <FormControlLabel
          control={
            <Switch
              checked={isMetric}
              onChange={handleUnitToggle}
              name="unitSwitch"
            />
          }
          label={isMetric ? "Metric (CM)" : "Imperial (IN)"}
          sx={toggleLabelStyle}
        />

        <Box sx={tooltipContainerStyle}>
          <Typography variant="body2" sx={tooltipTitleStyle}>
            How to Measure
          </Typography>

          <Tooltip
            title={
              <Box>
                <Typography variant="body2">
                  How to Measure Your Garments for the Best Fit:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Use a garment that fits you as a reference for inputting
                  measurements.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  To get the most accurate fit, follow these steps:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  • Lay the garment flat on a surface to measure. • Use a
                  measuring tape to take the garment’s width, length and key
                  dimensions. • When entering your measurements, please keep in
                  mind that clothes may shrink or stretch over time.
                </Typography>
              </Box>
            }
            arrow
            placement="top"
          >
            <IconButton aria-label="how to measure">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={measurementGridStyle}>
        {fields.map((field) => (
          <React.Fragment key={field}>
            <TextField
              name={field}
              label={`${getLabelForField(
                selectedCategory,
                selectedSubcategory,
                field
              )}`}
              value={measurements[field]}
              onChange={handleMeasurementChange}
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />

            <TextField
              name={`${field}Max`}
              label={`${getLabelForField(
                selectedCategory,
                selectedSubcategory,
                field
              )} (Max)`}
              value={measurements[`${field}Max` as keyof Measurements]}
              onChange={handleMeasurementChange}
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />
          </React.Fragment>
        ))}
      </Box>

      <Button
        variant="contained"
        onClick={handleSaveMeasurements}
        sx={saveButtonStyle}
        startIcon={saved ? <CheckCircleIcon /> : null}
      >
        {saved ? "Measurements Saved" : "Save Measurements"}
      </Button>
    </Box>
  );
};

export default AdvancedSearch;

