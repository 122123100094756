// Home.tsx





import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductList from '../components/ProductList';
import FilterComponent from '../components/FilterComponent';
import { useShopLogic } from '../hooks/useShopLogic';
import { trackEvent } from '../analytics';
import { SelectChangeEvent } from '@mui/material/Select';

const Home: React.FC = () => {
  const theme = useTheme();

  const {
    page,
    selectedCategory,
    selectedSubcategory,
    selectedSize,
    priceRange,
    sizeInputValue,
    categories,
    loading,
    error,
    totalPages,
    handleCategoryChange,
    handleSubcategoryChange,
    handleSizeChange,
    handlePriceChange,
    handleSizeInputChange,
    goToNextPage,
    goToPreviousPage,
    handleSearch,
    setSelectedType,
    advancedSearchResults,
    clearAdvancedSearchResults,
  } = useShopLogic();

  const handleAdvancedPriceChange = (event: Event, value: number | number[]) => {
    handlePriceChange(event, value);
    clearAdvancedSearchResults();
  };

  const onCategoryChange = (event: SelectChangeEvent<string>) => {
    handleCategoryChange(event);
    clearAdvancedSearchResults();

    const newCategory = event.target.value as string;
    trackEvent('Filter', 'Change Category', `Category: ${newCategory}`);
  };

  const onSubcategoryChange = (event: SelectChangeEvent<string>) => {
    handleSubcategoryChange(event);
    clearAdvancedSearchResults();

    const newSubcategory = event.target.value as string;
    trackEvent('Filter', 'Change Subcategory', `Subcategory: ${newSubcategory}`);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: '20px', md: '40px' },
        backgroundColor: '#f8f9fa',
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ width: '100%', marginBottom: '20px' }}>
        <Header setSelectedType={setSelectedType} />
      </Box>

      <FilterComponent
        selectedCategory={selectedCategory}
        selectedSubcategory={selectedSubcategory}
        selectedSize={selectedSize}
        priceRange={priceRange}
        sizeInputValue={sizeInputValue}
        categories={categories}
        loading={loading}
        error={error}
        handleCategoryChange={onCategoryChange}
        handleSubcategoryChange={onSubcategoryChange}
        handleSizeChange={(event, value) => {
          handleSizeChange(event, value);
          clearAdvancedSearchResults();
        }}
        handleAdvancedPriceChange={handleAdvancedPriceChange}
        handleSizeInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleSizeInputChange(event);
          clearAdvancedSearchResults();
        }}
        handleSearch={handleSearch}
      />

      <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}
        >
          {selectedCategory}
        </Typography>
      </Box>

      <ProductList
        selectedCategory={selectedCategory}
        selectedSize={selectedSize}
        // priceRange={priceRange}
        products={advancedSearchResults || []} // Pass an empty array if advancedSearchResults is null
        currentPage={page}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        advancedSearchResults={advancedSearchResults}
      />

      <Box sx={{ width: '100%', marginTop: '20px' }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
