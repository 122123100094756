// Footer.tsx

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Link } from 'react-router-dom';
import useMailchimpSignup from '../hooks/useMailChimpSignup';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');
  const { subscribe, isLoading, error, success } = useMailchimpSignup();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (email) {
      await subscribe(email);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/* New Section Above Footer */}
      <Box
        sx={{
          backgroundColor: '#f8f4f0',
          padding: '20px',
          color: '#3c2312',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          We know how hard it is to find clothes that fit right.
        </Typography>
        <Typography variant="body2" sx={{ mb: 3, maxWidth: '600px', mx: 'auto' }}>
          Size charts can be confusing. But with FindMyFashion, all that guessing is over! Our app uses
          your measurements to sort through clothes that fit you best—all in just a few clicks.
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', mb: 3 }}>
          {['No More Size Guessing!', 'Quick and Easy!', 'Less Frustrating Returns!', 'Reduce Shipping Waste!'].map(
            (text, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <ThumbUpAltIcon />
                <Typography variant="body2">{text}</Typography>
              </Box>
            )
          )}
        </Box>

        <Button
          variant="contained"
          color="secondary"
          onClick={scrollToTop}
          sx={{
            backgroundColor: '#6d4c41',
            '&:hover': {
              backgroundColor: '#5d4037',
            },
            fontSize: '14px',
            padding: '8px 16px',
          }}
        >
          I’m ready
        </Button>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          backgroundColor: '#3c2312',
          color: '#fff',
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          gap: '20px',
        }}
      >
        {/* Contact Us Section */}
        <Box sx={{ flex: '1', minWidth: '200px' }}>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '16px' }}>
            Contact Us
          </Typography>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '16px' }}>
            <Link to="/about" style={{ color: '#fff', textDecoration: 'none' }}>
              About Us
            </Link>
          </Typography>
          <Typography variant="body2">hello@findmy.fashion</Typography>
        </Box>

        {/* Newsletter Section */}
        <Box sx={{ flex: '1', minWidth: '200px', maxWidth: '300px' }}>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '16px' }}>
            Stay up to date
          </Typography>
          <Box component="form" sx={{ display: 'flex', gap: '10px' }} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              size="small"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                flex: 1,
              }}
              InputProps={{
                sx: {
                  borderRadius: '4px',
                  color: '#000',
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                backgroundColor: '#6d4c41',
                '&:hover': {
                  backgroundColor: '#5d4037',
                },
                fontSize: '14px',
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Box>
          {error && <Typography color="error" variant="body2" sx={{ mt: 1 }}>{error}</Typography>}
          {success && <Typography color="success" variant="body2" sx={{ mt: 1 }}>Subscribed successfully!</Typography>}
        </Box>

        {/* Footer Bottom Section */}
        <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography variant="body2" sx={{ borderTop: '1px solid #fff', pt: 2 }}>
            &copy; {new Date().getFullYear()} FindMy.Fashion. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
