// ProductCard.tsx


import React, { memo } from 'react';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { Product } from '../hooks/useShopLogic'; 

interface ProductCardProps {
  product: Product; 
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = '/path-to-fallback-image.jpg'; // Fallback image
  };

  // Function to safely format the price
  const formatPrice = (price: number | null | undefined): string => {
    if (typeof price === 'number' && !isNaN(price)) {
      return price.toFixed(2);
    }
    return 'N/A';
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: '100%', sm: 345 },
        width: '100%',
        boxShadow: 4,
        borderRadius: 4,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 6,
        },
        margin: { xs: '20px 0', sm: '20px' },
      }}
      aria-label={`Product card for ${product.name}`}
    >
      <CardActionArea href={product.url} target="_blank" aria-label={`Go to ${product.name}`}>
        <CardMedia
          component="img"
          height="320" // Increased height for a longer card
          image={product.img_url}
          alt={product.name}
          onError={handleImageError} // Move the event handler here
          sx={{
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            objectFit: 'cover', // Ensures the image covers the entire area
            width: '100%', // Ensures the image takes the full width of the card
            transition: 'opacity 0.3s ease-in-out',
            '&:hover': {
              opacity: 0.9,
            },
          }}
        />
        <CardContent
          sx={{
            padding: { xs: '10px', sm: '16px' },
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          {/* Best Match Rank and Size */}
          {product.matchRank && (
            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
              {product.matchRank} {/* Display the best match rank */}
              <Typography display="block">Size:  {product.size} </Typography>
            </Typography>
          )}

          {/* Designer */}
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              fontSize: { xs: '1rem', sm: '1.25rem' },
              fontWeight: 'bold',
              lineHeight: '1.2',
            }}
          >
            {product.designer}
          </Typography>
          
          {/* Product Name */}
          <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
            {product.name}
          </Typography>

          {/* Price */}
          <Typography variant="body1" color="text.primary" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            {product.price_currency}{' '}
            {formatPrice(product.price)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

// Memoize component to prevent unnecessary re-renders
export default memo(ProductCard);
