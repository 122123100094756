// MeasurementsPage.tsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  Paper,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Measurements: React.FC = () => {
  // Define the state with the correct shape
  const [measurements, setMeasurements] = useState<{
    length: string;
    chest: string;
    waist: string;
    hip: string;
    shoulder: string;
    sleeve: string;
  }>({
    length: '',
    chest: '',
    waist: '',
    hip: '',
    shoulder: '',
    sleeve: '',
  });

  const [isMetric, setIsMetric] = useState(true); // True for cm, false for inches
  const [saved, setSaved] = useState(false); // State to show if the measurements are saved
  const theme = useTheme(); // Using theme for consistent styling

  // Handle input change for measurements
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (parseFloat(value) < 0) return; // Prevent negative values
    setMeasurements((prev) => ({ ...prev, [name]: value }));
    setSaved(false); // Reset saved status on input change
  };

  // Toggle between cm and inches
  const handleUnitToggle = () => {
    setIsMetric((prev) => !prev);
    const conversionFactor = isMetric ? 0.393701 : 2.54; // Convert cm to inches or vice versa

    setMeasurements((prev) => ({
      length: (parseFloat(prev.length) * conversionFactor).toFixed(2),
      chest: (parseFloat(prev.chest) * conversionFactor).toFixed(2),
      waist: (parseFloat(prev.waist) * conversionFactor).toFixed(2),
      hip: (parseFloat(prev.hip) * conversionFactor).toFixed(2),
      shoulder: (parseFloat(prev.shoulder) * conversionFactor).toFixed(2),
      sleeve: (parseFloat(prev.sleeve) * conversionFactor).toFixed(2),
    }));
    setSaved(false); // Reset saved status on unit change
  };

  // Save measurements to local storage
  const saveMeasurements = () => {
    localStorage.setItem('customMeasurements', JSON.stringify(measurements));
    setSaved(true); // Set saved status
  };

  return (
    <Box
      sx={{
        padding: { xs: '16px', sm: '24px', md: '32px' },
        maxWidth: '600px',
        margin: '0 auto',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: '24px', color: theme.palette.text.primary }}>
        Set Your Measurements
      </Typography>

      <Paper
        elevation={3}
        sx={{
          padding: '24px',
          borderRadius: '8px',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <FormControlLabel
          control={<Switch checked={isMetric} onChange={handleUnitToggle} name="unitSwitch" />}
          label={isMetric ? 'Metric (CM)' : 'Imperial (IN)'}
          sx={{ marginBottom: '24px' }}
        />

        <Divider sx={{ marginBottom: '24px' }} />

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px' }}>
          {Object.entries(measurements).map(([key, value]) => (
            <TextField
              key={key}
              name={key}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={value}
              onChange={handleChange}
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />
          ))}
        </Box>

        <Button
          variant="contained"
          onClick={saveMeasurements}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            marginTop: '32px',
            padding: '10px 20px',
            fontSize: '16px',
          }}
          startIcon={saved ? <CheckCircleIcon /> : null}
        >
          {saved ? 'Measurements Saved' : 'Save Measurements'}
        </Button>
      </Paper>
    </Box>
  );
};

export default Measurements;
