// Banner.tsx



import React from 'react';
import { Box, Typography } from '@mui/material';
import { bannerStyles, bannerTextStyles } from './BannerStyles';

const Banner: React.FC = () => {
  return (
    <Box sx={bannerStyles}>
      <Typography variant="h4" component="div" sx={bannerTextStyles.title}>
        The Free Fashion App Designed to Help You Find Your Fit
      </Typography>
      <Typography variant="body1" component="div" sx={bannerTextStyles.subtitle}>
        Say goodbye to endless searching and help reduce returns for a greener planet!
      </Typography>
      <Typography variant="body2" component="div" sx={bannerTextStyles.content}>
        <strong>How it works:</strong><br />
        ▪ Choose Your Section: Women, Men or Kids<br />
        ▪ Select a Retailer: Mytheresa and more (coming soon)<br />
        ▪ Pick a Category: Tops, bottoms, dresses and more<br />
        ▪ Enter Your Measurements: Measure a garment that fits you well<br />
        ▪ Set Your Budget: Use the price range tool<br />
        ▪ Your Last Step: Hit Search and we’ll sort all available clothes across multiple brands, ranked by best match<br />
        ▪ You’re All Set: Explore clothes sorted by fit!
      </Typography>
    </Box>
  );
};

export default Banner;
