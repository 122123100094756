// BannerStyles.ts
export const bannerStyles = {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    marginTop: '20px',
  };
  
  export const bannerTextStyles = {
    title: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      marginBottom: '10px',
    },
    subtitle: {
      fontSize: '1.2rem',
      marginBottom: '10px',
      color: '#777',
    },
    content: {
      fontSize: '1rem',
      color: '#555',
    },
  };
  