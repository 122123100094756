// ProductList.tsx



import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ProductCard from './ProductCard';
import { Product } from '../hooks/useShopLogic';

interface ProductListProps {
  selectedCategory: string;
  selectedSize: string | null;
  products: Product[];
  currentPage: number;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  advancedSearchResults: Product[] | null; // Determine if advanced filter is enabled
}

const ProductList: React.FC<ProductListProps> = ({
  selectedCategory,
  selectedSize,
  products,
  currentPage,
  goToNextPage,
  goToPreviousPage,
  advancedSearchResults, // Destructure the new prop
}) => {
  const limit = 20;

  // Use advanced search results if available, otherwise fallback to normal products
  const productList = advancedSearchResults || products;

  // Calculate the total number of pages based on the product list
  const totalPages = Math.ceil(productList.length / limit);

  if (!productList.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No products found.
        </Typography>
      </Box>
    );
  }

  const startIndex = (currentPage - 1) * limit;
  const visibleProducts = productList.slice(startIndex, startIndex + limit);

  return (
    <Box sx={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
          gap: '20px',
          marginBottom: '40px',
        }}
      >
        {visibleProducts.map((product) => (
          <ProductCard key={product.uniqueKey} product={product} />
        ))}
      </Box>

      {productList.length > limit && ( // Render pagination only if there are multiple pages
        <Box
          sx={{ display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center', marginTop: '20px' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ProductList;
