// FilterComponent.tsx

import React from 'react';
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material';
import AdvancedSearch from './AdvanceSearch';
import RetailerDropdown from './RetailerDropdown';
import { fieldsMap } from '../utils/mappings';
import { Measurements } from '../utils/types';

import {
  containerStyle,
  retailerDropdownStyle,
  inputGroupStyle,
  selectStyle,
  selectedCategoryImageStyle,
  priceRangeWrapperStyle,
  priceRangeContainerStyle,
  priceRangeTextStyle,
  toTextStyle,
  buttonStyle,
  priceInputStyle,
} from './FilterComponentStyles';

interface FilterComponentProps {
  selectedCategory: string;
  selectedSubcategory: string;
  selectedSize: string | null;
  priceRange: number[]; // [minPrice, maxPrice]
  sizeInputValue: number | '';
  categories: {
    category: string;
    subcategories: {
      subcategory: string;
      genders: { gender: string; measurementChartUrl: string }[];
    }[];
  }[];
  loading: boolean;
  error: string | null;
  handleCategoryChange: (event: SelectChangeEvent<string>) => void;
  handleSubcategoryChange: (event: SelectChangeEvent<string>) => void;
  handleSizeChange: (event: Event, newValue: number | number[]) => void;
  handleAdvancedPriceChange: (event: Event, newValue: number | number[]) => void;
  handleSizeInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (searchParams: {
    filteredMeasurements: Partial<Measurements>;
    minPrice: number | undefined;
    maxPrice: number | undefined;
    priceFilterActive: boolean;
  }) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  selectedCategory,
  selectedSubcategory,
  selectedSize,
  priceRange,
  sizeInputValue,
  categories,
  loading,
  error,
  handleCategoryChange,
  handleSubcategoryChange,
  handleSizeChange,
  handleAdvancedPriceChange,
  handleSizeInputChange,
  handleSearch,
}) => {
  // const theme = useTheme();
  const [selectedRetailer, setSelectedRetailer] = React.useState('Mytheresa');
  const [measurements, setMeasurements] = React.useState({
    length: '',
    lengthMax: '',
    chest: '',
    chestMax: '',
    waist: '',
    waistMax: '',
    hip: '',
    hipMax: '',
    shoulder: '',
    shoulderMax: '',
    sleeve: '',
    sleeveMax: '',
    thigh: '',
    thighMax: '',
    inseam: '',
    inseamMax: '',
    rise: '',
    riseMax: '',
    collarWidth: '',
    collarWidthMax: '',
    waist_trouser: '',
    waist_trouserMax: '',
    hip_trouser: '',
    hip_trouserMax: '',
  });
  const [isMetric, setIsMetric] = React.useState(true);
  const [saved, setSaved] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [minPrice, setMinPrice] = React.useState<number | ''>('');
  const [maxPrice, setMaxPrice] = React.useState<number | ''>('');
  const [categorySelected, setCategorySelected] = React.useState(false);

  const handleRetailerChange = (event: SelectChangeEvent<string>) => {
    setSelectedRetailer(event.target.value as string);
  };

  const handleMeasurementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (parseFloat(value) < 0) return;
    setMeasurements((prev) => ({ ...prev, [name]: value }));
    setSaved(false);
  };

  const handleUnitToggle = () => {
    setIsMetric((prev) => !prev);
    const conversionFactor = isMetric ? 0.393701 : 2.54;
    setMeasurements((prev) => ({
      length: prev.length ? (parseFloat(prev.length) * conversionFactor).toFixed(2) : '',
      chest: prev.chest ? (parseFloat(prev.chest) * conversionFactor).toFixed(2) : '',
      waist: prev.waist ? (parseFloat(prev.waist) * conversionFactor).toFixed(2) : '',
      hip: prev.hip ? (parseFloat(prev.hip) * conversionFactor).toFixed(2) : '',
      shoulder: prev.shoulder ? (parseFloat(prev.shoulder) * conversionFactor).toFixed(2) : '',
      sleeve: prev.sleeve ? (parseFloat(prev.sleeve) * conversionFactor).toFixed(2) : '',
      thigh: prev.thigh ? (parseFloat(prev.thigh) * conversionFactor).toFixed(2) : '',
      inseam: prev.inseam ? (parseFloat(prev.inseam) * conversionFactor).toFixed(2) : '',
      rise: prev.rise ? (parseFloat(prev.rise) * conversionFactor).toFixed(2) : '',
      collarWidth: prev.collarWidth ? (parseFloat(prev.collarWidth) * conversionFactor).toFixed(2) : '',
      waist_trouser: prev.waist_trouser ? (parseFloat(prev.waist_trouser) * conversionFactor).toFixed(2) : '',
      hip_trouser: prev.hip_trouser ? (parseFloat(prev.hip_trouser) * conversionFactor).toFixed(2) : '',
      lengthMax: prev.lengthMax ? (parseFloat(prev.lengthMax) * conversionFactor).toFixed(2) : '',
      chestMax: prev.chestMax ? (parseFloat(prev.chestMax) * conversionFactor).toFixed(2) : '',
      waistMax: prev.waistMax ? (parseFloat(prev.waistMax) * conversionFactor).toFixed(2) : '',
      hipMax: prev.hipMax ? (parseFloat(prev.hipMax) * conversionFactor).toFixed(2) : '',
      shoulderMax: prev.shoulderMax ? (parseFloat(prev.shoulderMax) * conversionFactor).toFixed(2) : '',
      sleeveMax: prev.sleeveMax ? (parseFloat(prev.sleeveMax) * conversionFactor).toFixed(2) : '',
      thighMax: prev.thighMax ? (parseFloat(prev.thighMax) * conversionFactor).toFixed(2) : '',
      inseamMax: prev.inseamMax ? (parseFloat(prev.inseamMax) * conversionFactor).toFixed(2) : '',
      riseMax: prev.riseMax ? (parseFloat(prev.riseMax) * conversionFactor).toFixed(2) : '',
      collarWidthMax: prev.collarWidthMax ? (parseFloat(prev.collarWidthMax) * conversionFactor).toFixed(2) : '',
      waist_trouserMax: prev.waist_trouserMax ? (parseFloat(prev.waist_trouserMax) * conversionFactor).toFixed(2) : '',
      hip_trouserMax: prev.hip_trouserMax ? (parseFloat(prev.hip_trouserMax) * conversionFactor).toFixed(2) : '',
    }));
    setSaved(false);
  };

  const saveMeasurements = () => {
    localStorage.setItem('customMeasurements', JSON.stringify(measurements));
    setSaved(true);
  };

  const executeSearch = async () => {
    setSearchLoading(true);

    const fieldsForSelectedCategory: (keyof Measurements)[] =
      fieldsMap[selectedCategory]?.[selectedSubcategory] ||
      fieldsMap[selectedCategory]?.Default ||
      [];

    const filteredMeasurements = Object.keys(measurements)
      .filter(
        (key): key is keyof Measurements =>
          fieldsForSelectedCategory.includes(key as keyof Measurements) ||
          fieldsForSelectedCategory.includes(key.replace('Max', '') as keyof Measurements)
      )
      .reduce((obj, key) => {
        if (measurements[key] !== '') {
          obj[key] = measurements[key];
        }
        return obj;
      }, {} as Partial<Measurements>);

    await handleSearch({
      filteredMeasurements,
      minPrice: minPrice !== '' ? minPrice : undefined,
      maxPrice: maxPrice !== '' ? maxPrice : undefined,
      priceFilterActive: minPrice !== '' || maxPrice !== '',
    });

    setSearchLoading(false);
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? parseInt(event.target.value, 10) : undefined;
    setMinPrice(value ?? '');
    handleAdvancedPriceChange(event as any, [value || 0, maxPrice || 0]);
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? parseInt(event.target.value, 10) : undefined;
    setMaxPrice(value ?? '');
    handleAdvancedPriceChange(event as any, [minPrice || 0, value || 0]);
  };

  const handleCategorySelection = (event: SelectChangeEvent<string>) => {
    setCategorySelected(true);
    handleCategoryChange(event);
  };

  const selectedCategoryObj = categories.find((cat) => cat.category === selectedCategory);
  const subcategories = selectedCategoryObj ? selectedCategoryObj.subcategories : [];
  const selectedCategoryImage =
    subcategories.find((sub) => sub.subcategory === selectedSubcategory)?.genders[0].measurementChartUrl ||
    '';

  return (
    <Box sx={containerStyle}>
      <RetailerDropdown selectedRetailer={selectedRetailer} handleRetailerChange={handleRetailerChange} />
      <Box sx={retailerDropdownStyle}>
        <Box sx={inputGroupStyle}>
          <Select
            displayEmpty
            sx={selectStyle}
            onChange={handleCategorySelection}
            value={categorySelected ? selectedCategory : ''}
          >
            <MenuItem value="">
              <em>Category</em>
            </MenuItem>
            {loading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : error ? (
              <MenuItem disabled>{error}</MenuItem>
            ) : (
              categories.map((categoryObj) => (
                <MenuItem key={categoryObj.category} value={categoryObj.category}>
                  {categoryObj.category.replace(/_/g, ' ')}
                </MenuItem>
              ))
            )}
          </Select>

          {categorySelected && (
            <Select
              displayEmpty
              sx={selectStyle}
              onChange={handleSubcategoryChange}
              value={selectedSubcategory || ''}
            >
              <MenuItem value="">
                <em>Subcategory</em>
              </MenuItem>
              {subcategories.map((subcategoryObj) => (
                <MenuItem key={subcategoryObj.subcategory} value={subcategoryObj.subcategory}>
                  {subcategoryObj.subcategory.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          )}

          {selectedCategoryImage && (
            <Box
              sx={{
                ...selectedCategoryImageStyle,
                backgroundImage: `url(${selectedCategoryImage})`,
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '16px',
            alignItems: 'center',
            flex: 2,
          }}
        >
          <AdvancedSearch
            measurements={measurements}
            isMetric={isMetric}
            saved={saved}
            handleUnitToggle={handleUnitToggle}
            handleMeasurementChange={handleMeasurementChange}
            saveMeasurements={saveMeasurements}
            selectedCategory={selectedCategory}
            selectedSubcategory={selectedSubcategory}
          />

          <Box sx={priceRangeWrapperStyle}>
            <Typography
              variant="body2"
              sx={priceRangeTextStyle}
            >
              Price Range
            </Typography>
            <Box sx={priceRangeContainerStyle}>
              <input
                type="number"
                value={minPrice}
                placeholder="Min Price"
                onChange={handleMinPriceChange}
                style={priceInputStyle}
              />
              <Typography variant="body1" sx={toTextStyle}>
                to
              </Typography>
              <input
                type="number"
                value={maxPrice}
                placeholder="Max Price"
                onChange={handleMaxPriceChange}
                style={priceInputStyle}
              />
            </Box>
          </Box>

          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={executeSearch}
            disabled={searchLoading || !categorySelected}
          >
            {searchLoading ? <CircularProgress size={24} /> : 'Search'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterComponent;





