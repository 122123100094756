//useShopLogic.ts



import { useState, useEffect, useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material';

export interface Product {
  [key: string]: string | number | null;
  category: string;
  subcategory: string;
  designer: string;
  id: string;
  img_url: string;
  measurementName: string;
  measurement_chart_id: string;
  name: string;
  price: number;
  price_currency: string;
  price_original: number;
  seller: string;
  type: string;
  url: string;
  size: string;        
  distance: number;     
  uniqueKey: string;   
}

export const sizeMap: Record<string, number> = {
  XXS: 30,
  XS: 32,
  S: 34,
  M: 36,
  L: 38,
  XL: 40,
  XL_PLUS: 42,
  "36": 36,
  "38": 38,
  "40": 40,
  "42": 42,
  "44": 44,
  "46": 46,
  "48": 48,
  "50": 50,
};

// Fetch categories from the API using base URL from environment
const fetchCategories = async (): Promise<{ category: string, subcategories: { subcategory: string, genders: { gender: string, measurementChartUrl: string }[] }[] }[]> => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/categories`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};

// Main hook to manage shop data and logic
export const useShopLogic = () => {
  const [advancedPage, setAdvancedPage] = useState(1); // Separate page state for advanced filtering
  const limit = 20;

  // UI State for selections
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 5000]); // Used for advanced search
  const [sizeInputValue, setSizeInputValue] = useState<number | ''>('');
  const [selectedType, setSelectedType] = useState<string>('');

  const [priceFilterEnabled, setPriceFilterEnabled] = useState<boolean>(false); // Toggle for enabling/disabling price filter


  // Active filtering state, used only when search is triggered
  const [activeCategory, setActiveCategory] = useState('');
  const [activeSubcategory, setActiveSubcategory] = useState('');
  const [activeSize, setActiveSize] = useState<string | null>(null);
  const [activeType, setActiveType] = useState<string>('');

  const [advancedSearchResults, setAdvancedSearchResults] = useState<Product[] | null>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState<{ category: string, subcategories: { subcategory: string, genders: { gender: string, measurementChartUrl: string }[] }[] }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const categoryData = await fetchCategories();
        const filteredCategories = selectedType === 'kids'
          ? categoryData
          : selectedType
          ? categoryData.map(category => ({
              ...category,
              subcategories: category.subcategories.filter(subcategory =>
                subcategory.genders.some(gender => gender.gender === selectedType)
              )
            })).filter(category => category.subcategories.length > 0)
          : categoryData.filter(category => 
              !category.subcategories.some(subcategory =>
                subcategory.genders.some(gender => gender.gender === 'kids')
              )
            );
  
        setCategories(filteredCategories);
      } catch (error: any) {
        console.error('Error loading data:', error.message);
        setError(error.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
  
    fetchInitialData();  
  }, [selectedType]);
  
  useEffect(() => {
    if (advancedSearchResults) {
      setTotalPages(Math.ceil(advancedSearchResults.length / limit));
    }
  }, [advancedSearchResults, limit]);

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory(''); // Clear subcategory selection when category changes
  };

  const handleSubcategoryChange = (event: SelectChangeEvent<string>) => {
    setSelectedSubcategory(event.target.value);
  };

  const handleSizeChange = (event: Event, newValue: number | number[]) => {
    const sizeValue = newValue as number;
    const sizeLabel = Object.entries(sizeMap).find(
      ([, value]) => value === sizeValue
    )?.[0];
    setSelectedSize(sizeLabel || null);
    setSizeInputValue(sizeValue);
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as [number, number]);
  };

  const handleSizeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setSizeInputValue(event.target.value === '' ? '' : value);

    if (!isNaN(value) && value >= 30 && value <= 70) {
      const sizeLabel = Object.entries(sizeMap).find(
        ([, v]) => v === value
      )?.[0];
      setSelectedSize(sizeLabel || null);
    } else if (event.target.value === '') {
      setSelectedSize(null);
    }
  };

  const goToNextPage = () => {
    if (advancedSearchResults) {
      setAdvancedPage((prevPage) => Math.min(prevPage + 1, totalPages));
    }
  };

  const goToPreviousPage = () => {
    if (advancedSearchResults) {
      setAdvancedPage((prevPage) => Math.max(prevPage - 1, 1));
    }
  };

  const handleSearch = useCallback(async (measurements: any) => {
    setActiveCategory(selectedCategory);
    setActiveSubcategory(selectedSubcategory);
    setActiveSize(selectedSize);
    setActiveType(selectedType);
  
    if (measurements) {
      try {
        setLoading(true);
  
        // Prepare the body of the request
        const body: Record<string, any> = {
          category: selectedCategory,
          subcategory: selectedSubcategory,
          page: advancedPage,
          limit,
        };
  
        // Add measurements and their max values if present
        const measurementWithMax = Object.keys(measurements).reduce((acc, key) => {
          acc[key] = measurements[key];
          const maxKey = `${key}Max`;
          if (measurements[maxKey] !== undefined && measurements[maxKey] !== '') {
            acc[maxKey] = measurements[maxKey];
          }
          return acc;
        }, {} as Record<string, any>);
  
        body.measurements = measurementWithMax;
  
        // Log the measurements to check for max values
        // console.log('Measurements with max values:', measurementWithMax);
        // console.log('Body to be sent to API:', body);
  
        // Only add price filtering if the price filter is enabled
        if (priceFilterEnabled) {
          body.priceMin = priceRange[0];
          body.priceMax = priceRange[1];
        }
  
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/advanced-search`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch search results');
        }
  
        const data = await response.json();
        // console.log('Response:', data);
  
        setAdvancedSearchResults(data.products);
        setTotalPages(Math.ceil(data.total / limit)); // Ensure `data.total` exists in the response
        setAdvancedPage(1);
      } catch (error) {
        console.error('Error during search:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [selectedCategory, selectedSubcategory, selectedSize, selectedType, advancedPage, limit, priceRange, priceFilterEnabled]);
  
  
  
  
  
  const clearAdvancedSearchResults = () => {
    setAdvancedSearchResults(null);
    setAdvancedPage(1);
  };

  const currentPage = advancedSearchResults ? advancedPage : 1;

  return {
    page: currentPage, 
    limit,
    selectedCategory,
    selectedSubcategory,
    selectedSize,
    priceRange,
    sizeInputValue,
    advancedSearchResults,
    categories,
    loading,
    error,
    totalPages,
    handleCategoryChange,
    handleSubcategoryChange,
    handleSizeChange,
    handlePriceChange,
    handleSizeInputChange,
    goToNextPage,
    goToPreviousPage,
    handleSearch,
    setSelectedType,
    clearAdvancedSearchResults,
  };
};

